/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppInputTileFeature = typeof AppInputTileFeature[keyof typeof AppInputTileFeature];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppInputTileFeature = {
  DroughtFactor: 'DroughtFactor',
  Precipitation: 'Precipitation',
  RelativeHumidity: 'RelativeHumidity',
  Temperature: 'Temperature',
  DewPointTemperature: 'DewPointTemperature',
  WindDirection: 'WindDirection',
  WindMagnitude: 'WindMagnitude',
} as const;
