/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * String which represents a Fire Danger Rating in the old NSW FDRS system (to be replaced with AFDRS in future): * L = low-moderate * H = high * V = very high * S = severe * E = extreme * C = catastrophic

 */
export type AppNswFdr = typeof AppNswFdr[keyof typeof AppNswFdr];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppNswFdr = {
  LOW_MODERATE: 'L',
  HIGH: 'H',
  VERY_HIGH: 'V',
  SEVERE: 'S',
  EXTREME: 'E',
  CATASTROPHIC: 'C',
} as const;
