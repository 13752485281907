/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceCreateAnnualWorksPlanForecastInputType = typeof ServiceCreateAnnualWorksPlanForecastInputType[keyof typeof ServiceCreateAnnualWorksPlanForecastInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCreateAnnualWorksPlanForecastInputType = {
  'annual-works-plan-forecast': 'annual-works-plan-forecast',
} as const;
