/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppPhoenixSettingsSettingsGrassCuringCoeffEquation = typeof AppPhoenixSettingsSettingsGrassCuringCoeffEquation[keyof typeof AppPhoenixSettingsSettingsGrassCuringCoeffEquation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppPhoenixSettingsSettingsGrassCuringCoeffEquation = {
  'Cheney_et_al_(1998)': 'Cheney et al (1998)',
  'Cruz_et_al_(2015)': 'Cruz et al (2015)',
} as const;
