/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppIndividualTreatmentComparisonScenario = typeof AppIndividualTreatmentComparisonScenario[keyof typeof AppIndividualTreatmentComparisonScenario];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppIndividualTreatmentComparisonScenario = {
  RR: 'RR',
  RT: 'RT',
} as const;
