/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppWeatherDataRelationshipObjectAllOfDataType = typeof AppWeatherDataRelationshipObjectAllOfDataType[keyof typeof AppWeatherDataRelationshipObjectAllOfDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppWeatherDataRelationshipObjectAllOfDataType = {
  weatherpackage: 'weatherpackage',
} as const;
