/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppOutputStatusFailedStatus = typeof AppOutputStatusFailedStatus[keyof typeof AppOutputStatusFailedStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppOutputStatusFailedStatus = {
  failed: 'failed',
} as const;
