/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppIndividualTreatmentComparisonBrigadeProjectType = typeof AppIndividualTreatmentComparisonBrigadeProjectType[keyof typeof AppIndividualTreatmentComparisonBrigadeProjectType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppIndividualTreatmentComparisonBrigadeProjectType = {
  'individual-treatment-comparison-brigade': 'individual-treatment-comparison-brigade',
} as const;
