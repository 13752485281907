/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * Aggregate grid size.
 */
export type AppPostprocessingSettingsSettingsAggScale = typeof AppPostprocessingSettingsSettingsAggScale[keyof typeof AppPostprocessingSettingsSettingsAggScale];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppPostprocessingSettingsSettingsAggScale = {
  NUMBER_360: 360,
  NUMBER_540: 540,
  NUMBER_720: 720,
  NUMBER_900: 900,
  NUMBER_1080: 1080,
  NUMBER_1260: 1260,
  NUMBER_1140: 1140,
  NUMBER_1620: 1620,
} as const;
