/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppOutputStatusReadyStatus = typeof AppOutputStatusReadyStatus[keyof typeof AppOutputStatusReadyStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppOutputStatusReadyStatus = {
  ready: 'ready',
} as const;
