/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

/**
 * The frequency of the notification
 */
export type ServiceSendNotificationFrequency = typeof ServiceSendNotificationFrequency[keyof typeof ServiceSendNotificationFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceSendNotificationFrequency = {
  instant: 'instant',
  daily: 'daily',
  weekly: 'weekly',
} as const;
