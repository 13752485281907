/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type ServiceCreateAnnualWorksPlanEvaluationInputType = typeof ServiceCreateAnnualWorksPlanEvaluationInputType[keyof typeof ServiceCreateAnnualWorksPlanEvaluationInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceCreateAnnualWorksPlanEvaluationInputType = {
  'annual-works-plan-evaluation': 'annual-works-plan-evaluation',
} as const;
