/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppNotificationFrequency = typeof AppNotificationFrequency[keyof typeof AppNotificationFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppNotificationFrequency = {
  none: 'none',
  instant: 'instant',
  daily: 'daily',
  weekly: 'weekly',
} as const;
