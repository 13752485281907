/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import { AppWeatherDataType } from './appWeatherDataType';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppWeatherPackageType = {...AppWeatherDataType,  mixed: 'mixed',
} as const
export type AppWeatherPackageType = typeof AppWeatherPackageType[keyof typeof AppWeatherPackageType] ;
