/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditRunFailedEventType = typeof AppAuditRunFailedEventType[keyof typeof AppAuditRunFailedEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditRunFailedEventType = {
  'run-failed': 'run-failed',
} as const;
