/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppOutputStatusProcessingStatus = typeof AppOutputStatusProcessingStatus[keyof typeof AppOutputStatusProcessingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppOutputStatusProcessingStatus = {
  processing: 'processing',
} as const;
