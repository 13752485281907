/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import { AppBfrmpScenario } from './appBfrmpScenario';
import { AppStatewideMaxFuelsScenario } from './appStatewideMaxFuelsScenario';
import { AppStatewideSnapshotScenario } from './appStatewideSnapshotScenario';
import { AppModelledImpactTimeScenario } from './appModelledImpactTimeScenario';
import { AppIndividualTreatmentComparisonScenario } from './appIndividualTreatmentComparisonScenario';
import { AppIndividualTreatmentComparisonBrigadeScenario } from './appIndividualTreatmentComparisonBrigadeScenario';
import { AppAnnualWorksPlanForecastScenario } from './appAnnualWorksPlanForecastScenario';
import { AppAnnualWorksPlanEvaluationScenario } from './appAnnualWorksPlanEvaluationScenario';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppRunScenario = {...AppBfrmpScenario,...AppStatewideMaxFuelsScenario,...AppStatewideSnapshotScenario,...AppModelledImpactTimeScenario,...AppIndividualTreatmentComparisonScenario,...AppIndividualTreatmentComparisonBrigadeScenario,...AppAnnualWorksPlanForecastScenario,...AppAnnualWorksPlanEvaluationScenario,} as const
export type AppRunScenario = typeof AppRunScenario[keyof typeof AppRunScenario] ;
