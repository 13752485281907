/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */

export type AppAuditWeatherZoneArchivedEventType = typeof AppAuditWeatherZoneArchivedEventType[keyof typeof AppAuditWeatherZoneArchivedEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAuditWeatherZoneArchivedEventType = {
  'weatherzone-archived': 'weatherzone-archived',
} as const;
